import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UsePreviousExample } from './use-previous.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "useprevious"
    }}>{`usePrevious`}</h1>
    <p>{`Will always return the previous value passed to the hook. It is useful if you
want to compare a new value to its previous value when it was last rendered.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useEffect } from 'react';
import { usePrevious, useInput } from '@fransvilhelm/hooks';

const Checkbox = () => {
  const input = useInput('');
  const previous = usePrevious(input.value);

  useEffect(() => {
    if (input.value !== previous) {
      // do side effect
    }
  });

  return <input {...input} />;
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UsePreviousExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UsePreviousExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UsePreviousExample mdxType="UsePreviousExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`usePrevious`}</inlineCode>{` accepts one parameter – the value. It will always return the
previous version of that value, meaning the value it had on the previous render.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`any`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Value to return`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`usePrevious`}</inlineCode>{` returns the state of the value from previous render. On initial
render the value will always be undefined.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      